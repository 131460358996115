import { useToast, UseToastOptions } from '@chakra-ui/react'

export const useToastCustom = () => {
  const toast = useToast()

  const toastImplementation = (opts: UseToastOptions) => {
    toast({
      ...opts,
      isClosable: true,
    })
  }

  return toastImplementation
}

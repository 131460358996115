import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { API_URL } from 'config/config.exporter'
import Router from 'next/router'
import { toast } from 'react-toastify'
import { ErrorsCodes } from 'source-of-truth/errorsCodes'
import { messages } from 'source-of-truth/messages'

axios.defaults.withCredentials = true

const instance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
})

const injectOAuthParams = (
  request: AxiosRequestConfig,
  searchParams: URLSearchParams,
) => {
  const accessToken = searchParams.get('accessToken')

  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`
  }
}

instance.interceptors.request.use(
  (request) => {
    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search)
      injectOAuthParams(request, searchParams)
    }

    return request
  },
  (error) => {
    console.log('error', error)
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error: Error | AxiosError) => {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        if (typeof window !== 'undefined' && 'Intercom' in window) {
          console.log('exists INTERCOM')
          window.Intercom('shutdown')
          Router.push('/login')
        }
      }

      if (error.response?.data?.code === ErrorsCodes.COMPANY_NOT_VERIFIED_YET) {
        toast(messages[ErrorsCodes.COMPANY_NOT_VERIFIED_YET])
      }
    }
    return Promise.reject(error)
  },
)

export default instance

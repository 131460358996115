import {
  Alert,
  AlertIcon,
  Box,
  chakra,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
} from '@chakra-ui/react'
import { authAtom } from 'atoms/app-props.atom'
import AutoinspectorLink from 'components/AutoinspectorLink'
import { ButtonChakra } from 'components/Button'
import Header from 'components/Header'
import { useLogin } from 'controllers/login.controller'
import { useAtomValue } from 'jotai'
import { GetServerSideProps } from 'next'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { Controller } from 'react-hook-form'
import { HiEye, HiEyeOff } from 'react-icons/hi'
import { AuthService } from 'services/auth.service'
import { UserService } from 'services/user.service'
import { compose } from 'ssr/compose'
import {
  GetInvitationTokenFetcher,
  IGetInvitationTokenFetcherPageProps,
} from 'ssr/middlewares/fetchers/get-invitation-token.fetcher'
import { auth } from 'ssr/middlewares/guards/auth.guard'
import { CanAccessPublicRoutes } from 'ssr/middlewares/guards/canAccessPublicRoutes.guard'

function Login(props: IGetInvitationTokenFetcherPageProps) {
  const controller = useLogin(new UserService(), props)

  const buildHref = () => {
    if (controller.nextPath) {
      return `/register?next=${encodeURIComponent(controller.nextPath)}`
    }

    if (props.invitation) {
      return `/register?invitationToken=${controller.router.query.invitationToken}`
    }

    return `/register`
  }

  return (
    <>
      <Header title='Iniciar sesión' />
      <Container
        maxW='container.sm'
        minH='100vh'
        display='flex'
        flexDirection='column'
        alignItems='center'
        pt='8'
      >
        <Box
          w={['100%', '100%', '80%']}
          mb='8'
          display='flex'
          justifyContent='flex-start'
          pl='4'
        >
          <AutoinspectorLink />
        </Box>
        {props.invitation && (
          <Box mb={4} w={['100%', '100%', '80%']}>
            <Alert status='info' borderRadius='lg'>
              <AlertIcon />
              <Box fontSize='xs'>
                Para aceptar la invitación de{' '}
                <chakra.span fontStyle={'italic'}>
                  {props.invitation.host.name}
                </chakra.span>
                , deberás iniciar sesión en tu cuenta
              </Box>
            </Alert>
          </Box>
        )}

        <Box
          px={['6', '6', '12']}
          py={['8', '8', '14']}
          w={['100%', '100%', '80%']}
          boxShadow='0px 3px 6px #00000029'
          borderRadius='landing'
          bg='white'
        >
          <chakra.form
            onSubmit={controller.handleSubmit(controller.onSubmit)}
            h='100%'
          >
            <Box display='flex' flexDirection='column'>
              <Box id='title' mb='6' minH='36px'>
                <Text fontSize='2xl' fontWeight='bold' whiteSpace='nowrap'>
                  Inicia sesión en tu cuenta
                </Text>
              </Box>

              <Box>
                <FormControl id='email' mb='8'>
                  <FormLabel fontWeight='bold' fontSize='0.875rem'>
                    Correo electrónico
                  </FormLabel>
                  <Input
                    size='lg'
                    type='text'
                    data-cy='email-login-input'
                    {...controller.register('user')}
                    variant='filled'
                    focusBorderColor='landingPrimary'
                    borderRadius={'landing'}
                    isInvalid={!!controller.errors.user}
                  />
                </FormControl>

                <FormControl>
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    mb='2'
                  >
                    <FormLabel fontWeight='bold' fontSize='0.875rem' m='0'>
                      Contraseña
                    </FormLabel>
                    <Link
                      href='/forgot-password'
                      as={NextLink}
                      fontSize='0.875rem'
                      color='landingPrimary'
                      _hover={{ color: 'primary' }}
                    >
                      ¿No recuerdas la contraseña?
                    </Link>
                  </Box>
                  <InputGroup>
                    <Input
                      {...controller.register('password')}
                      id='password'
                      size='lg'
                      type={controller.showPassword ? 'text' : 'password'}
                      variant='filled'
                      focusBorderColor='landingPrimary'
                      isInvalid={!!controller.errors.password}
                      borderRadius={'landing'}
                    />
                    <InputRightElement
                      w='4.5rem'
                      display='flex'
                      height='full'
                      justifyContent='flex-end'
                    >
                      <IconButton
                        onClick={() =>
                          controller.setShowPassword(!controller.showPassword)
                        }
                        icon={
                          controller.showPassword ? <HiEye /> : <HiEyeOff />
                        }
                        aria-label='Show password'
                        bg='transparent'
                        _focus={{ boxShadow: 'none' }}
                        _hover={{ background: 'transparent' }}
                        _active={{ background: 'transparent' }}
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Controller
                  name='remember'
                  control={controller.control}
                  render={({ field }) => (
                    <Checkbox {...field} size='sm' my='8'>
                      Mantener la sesión iniciada
                    </Checkbox>
                  )}
                />
              </Box>
              <Box>
                <Box>
                  <ButtonChakra
                    id='login-btn'
                    isLoading={controller.isLoading}
                    type='submit'
                    size='lg'
                    width='100%'
                    color='white'
                    bg='landingPrimary'
                    borderRadius={'landing'}
                  >
                    Ingresar
                  </ButtonChakra>
                </Box>
              </Box>
            </Box>
          </chakra.form>
        </Box>
        <Link href={buildHref()} as={NextLink} mt='4' fontSize='sm'>
          ¿No tienes una cuenta?{' '}
          <chakra.span fontWeight='bold'>Regístrate</chakra.span>.
        </Link>
      </Container>
    </>
  )
}

export const getServerSideProps: GetServerSideProps = compose(
  (ctx, pageProps, fn) => auth(ctx, pageProps, fn, new AuthService(), true),
  CanAccessPublicRoutes,
  (ctx, pageProps, fn) => GetInvitationTokenFetcher(ctx, pageProps, fn, true),
)

export default Login

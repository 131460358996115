import { IInviteUsers } from 'components/InviteUserOnboarding'
import _axios from 'utils/_axios'
import { IAuthzPolicy } from './authz.service'
import { IPaginationAbstract } from './membership.service'

export interface ICheckToken {
  guestHasAnAccount: boolean
  token: string
  email: string
  host: {
    name: string
    email: string
    username: string
  }
  companyName: string
  acceptFromEmail?: boolean
}

interface IUserMembership {
  email: string
  firstName: string
  lastName: string
}

export interface IMembershipPaginationPopulated {
  _id: string
  userId: IUserMembership
  internalId: string
  createdAt: string
  policy: IAuthzPolicy
  hostId: string
  host?: {
    userId: IUserMembership
  }
  teams: {
    _id: string
    name: string
  }[]
}

export interface IGuestWithPolicy {
  email: string
  internalId: string
  _id: string
  createdAt: string
  policy: IAuthzPolicy
  hostId: string
  host: {
    userId: IUserMembership
  }
}

export interface IGuestPagination extends IPaginationAbstract {
  data: IGuestWithPolicy[]
}

export interface IGuestDetail {
  policy: IAuthzPolicy
  email: string
  createdAt: string
}

export class GuestService {
  static async sendInvitations(
    data: IInviteUsers,
  ): Promise<{ success: boolean }> {
    const response = await _axios.post(`/v1/account/guests/invite`, data)
    return response.data
  }

  static async checkToken(
    secret: string | string[],
    cookie?: string,
  ): Promise<ICheckToken> {
    const response = await _axios.get(`/v1/account/guests/invitation/token`, {
      params: {
        secret,
      },
      headers: {
        cookie,
      },
    })

    return response.data
  }

  static async getInvitation(cookie?: string): Promise<ICheckToken> {
    const response = await _axios.get(`/v1/account/guests/invitation/me`, {
      headers: {
        cookie,
      },
    })
    return response.data
  }

  static async paginate(
    pagination: Partial<IPaginationAbstract>,
    cookie?: string,
  ): Promise<IGuestPagination> {
    const response = await _axios.get(`/v1/account/guests/company/list`, {
      params: pagination,
      headers: {
        cookie,
      },
    })
    return response.data
  }

  static async fetchGuest(
    guestId: string,
    cookie: string,
  ): Promise<IGuestDetail> {
    const response = await _axios.get(`/v1/account/guests/${guestId}`, {
      headers: {
        cookie,
      },
    })

    return response.data
  }

  static async acceptInvitation(token: string): Promise<IGuestDetail> {
    const response = await _axios.get(`/v1/account/guests/invitation/accept`, {
      params: { secret: token },
    })

    return response.data
  }

  static async update(
    guestId: string,
    update: { internalId: string },
  ): Promise<{ success: boolean }> {
    const response = await _axios.put(`/v1/account/guests/${guestId}`, update)
    return response.data
  }

  static async remove(guestId: string): Promise<{ success: boolean }> {
    const response = await _axios.delete(`/v1/account/guests/${guestId}`)
    return response.data
  }

  static async fetchInvitationToken(
    guestId: string,
  ): Promise<{ link: string }> {
    const response = await _axios.get(
      `/v1/account/guests/${guestId}/invitation/link`,
    )
    return response.data
  }

  static async resendInvitation(guestId: string): Promise<{ link: string }> {
    const response = await _axios.post(
      `/v1/account/guests/${guestId}/invitation/resend`,
    )

    return response.data
  }
}

import { ErrorsCodes } from './errorsCodes'

export const messages = {
  email_already_exist: 'Email en uso',
  user_already_exist: 'El usuario ya existe',
  [ErrorsCodes.USERNAME_ALREADY_EXIST]: 'Nombre de usuario en uso',
  invalid_old_password: 'La contraseña actual no es correcta',
  server_error: 'Algo salió mal. Inténtelo nuevamente',
  [ErrorsCodes.USER_ALREADY_EXIST_IN_COMPANY]: 'Ya perteneces a esta empresa.',
  [ErrorsCodes.COMPANY_NOT_VERIFIED_YET]:
    'Debes estar en una empresa verificada para realizar esta acción.',
  email_already_exists_in_company:
    'Correo electrónico ya existente en la empresa',
  unhealthy_webhook_endpoint:
    'El endpoint proporcionado no es valido. Asegurate de que se encuentre abierto',
  internalID_already_exists_in_company:
    'El identificador interno ya existe dentro de tu empresa.',
  membership_invitation_already_accepted: 'Esta invitación ya fue aceptada.',
  account_not_verified: 'Debes verificar tu cuenta para realizar esta acción',
  not_found_coupon: 'Cupón invalido',
  payment_validation_card_failed: 'No hemos podido verificar tu tarjeta.',
  payment_gateway_not_available:
    'Ha surgido un error con el método de pago. Intenta más tarde.',
  has_recent_cancelled_subscription:
    'Tienes una suscripción cancelada recientemente. Intenta más tarde.',
  coupon_not_belong_to_plan: 'No se puede aplicar el cupón a tu plan actual.',
  society_reason_already_exists:
    'La razon social ya se encuentra registrada y verificada por otra empresa.',
  cuit_already_exists:
    'El CUIT ya se encuentra registrado y verificado por otra empresa',
}

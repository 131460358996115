import { useRouter } from 'next/router'
import queryString from 'query-string'

export const useOAuth = () => {
  const router = useRouter()

  const nextQuery = router.query.next

  const handleRedirect = (fn: () => any) => {
    const parsed = queryString.parse(nextQuery)

    if (
      parsed?.path &&
      Array.isArray(parsed?.scopes) &&
      parsed?.client_app_id
    ) {
      const { path, ...query } = parsed

      return router.push({
        pathname: path,
        query,
      })
    }

    fn()
  }

  return {
    handleRedirect,
  }
}

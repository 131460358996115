import { yupResolver } from '@hookform/resolvers/yup'
import axios, { AxiosError } from 'axios'
import { useOAuth } from 'hooks/useOAuth'
import { useToastCustom } from 'hooks/useToastCustom'
import { ILoginInput } from 'interfaces/login.interface'
import Router, { useRouter } from 'next/router'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { LoginSchema } from 'schemas/login.schema'
import { GuestService } from 'services/guest.service'
import { UserService } from 'services/user.service'
import { messages } from 'source-of-truth/messages'
import { IGetInvitationTokenFetcherPageProps } from 'ssr/middlewares/fetchers/get-invitation-token.fetcher'

export const useLogin = (
  userService: UserService,
  props: IGetInvitationTokenFetcherPageProps
) => {
  const toast = useToastCustom()

  const [isLoading, setIsLoading] = useState(false)
  const submitRef = useRef(null)
  const [showPassword, setShowPassword] = useState(false)

  const router = useRouter()

  const nextPath = router.query.next

  const useOauth = useOAuth()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ILoginInput>({
    resolver: yupResolver(LoginSchema),
    defaultValues: { remember: false },
  })

  const acceptInvitation = () => {
    GuestService.acceptInvitation(props.invitation.token)
      .then(res => {})
      .catch(err => {
        setIsLoading(false)
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 401) {
            return toast({
              description: 'La invitación es invalida.',
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          }

          toast({
            description:
              messages[err?.response?.data?.code] || messages.server_error,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
      })
  }

  const onSubmit = (data: ILoginInput) => {
    setIsLoading(true)
    userService
      .login(data)
      .then(() => {
        useOauth.handleRedirect(() => {
          Router.push('/')
        })
        if (props.invitation) {
          acceptInvitation()
        }
      })
      .catch((error: Error | AxiosError) => {
        setIsLoading(false)
        if (axios.isAxiosError(error)) {
          return toast({
            title:
              messages[error.response?.data.code] ||
              'Usuario o contraseña invalida.',
            status: 'error',
            isClosable: true,
          })
        }
        toast({
          title: messages.server_error,
          status: 'error',
          isClosable: true,
        })
      })
  }

  return {
    onSubmit,
    register,
    handleSubmit,
    errors,
    submitRef,
    isLoading,
    showPassword,
    setShowPassword,
    control,
    nextPath,
    router,
  }
}

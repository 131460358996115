import _axios from 'utils/_axios'
import {
  IUser,
  IUpdateUsernameEmail,
  IUpdateUserPasswordInput,
  IForgotPassword,
} from 'interfaces/user.interface'
import { ILoginInput } from 'interfaces/login.interface'
import { IRegisterInput, IRegisterOutput } from 'interfaces/register.interface'
import { IAPISuccessMessage } from 'interfaces/company.interface'
import { RegisterSchemaType } from 'schemas/register.schema'

export class UserService {
  async login(input: ILoginInput): Promise<IUser> {
    const response = await _axios.post('/v1/account/user/login', input, {
      withCredentials: true,
    })
    return response.data
  }

  static async register(input: RegisterSchemaType): Promise<IRegisterOutput> {
    const response = await _axios.post('/v1/account/user/register', input, {
      withCredentials: true,
    })
    return response.data
  }

  static async registerInvitation(
    input: RegisterSchemaType,
    token: string
  ): Promise<IRegisterOutput> {
    const response = await _axios.post(
      `/v1/account/user/register/invitation?secret=${token}`,
      input
    )
    return response.data
  }

  async getMe(): Promise<IUser> {
    const response = await _axios.get('/v1/account/user/me')
    return response.data
  }

  static async logout(): Promise<IAPISuccessMessage> {
    const message = await _axios.get('/v1/account/user/logout')
    return message.data
  }

  async resendMailVerification(): Promise<IAPISuccessMessage> {
    const response = await _axios.post('/v1/account/user/email-verification')
    return response.data
  }

  async confirmAccount(token: string): Promise<IAPISuccessMessage> {
    const response = await _axios.put(
      '/v1/account/user/email-verification',
      {},
      {
        headers: {
          authorization: token,
        },
      }
    )
    return response.data
  }

  async updateUsernameEmail(
    input: IUpdateUsernameEmail
  ): Promise<IAPISuccessMessage> {
    const response = await _axios.put('/v1/account/user/me', input)
    return response.data
  }

  async changePassword(
    input: IUpdateUserPasswordInput
  ): Promise<IAPISuccessMessage> {
    const response = await _axios.post(
      '/v1/account/user/private/reset-password',
      input
    )
    return response.data
  }

  async AcceptInvitation(): Promise<IAPISuccessMessage> {
    const response = await _axios.post('/v1/account/user/accept-invitation')
    return response.data
  }

  async forgotPassword(input: IForgotPassword): Promise<IAPISuccessMessage> {
    const response = await _axios.post(
      '/v1/account/user/forgot-password',
      input
    )
    return response.data
  }

  async getPublicResetPassword(
    token: string | string[] | undefined
  ): Promise<IAPISuccessMessage> {
    const response = await _axios.get(
      '/v1/account/user/public/reset-password',
      {
        headers: {
          authorization: token,
        },
      }
    )
    return response.data
  }

  async publicResetPassword(
    input: Omit<IUpdateUserPasswordInput, 'oldPassword'>,
    token: string | string[] | undefined
  ): Promise<IAPISuccessMessage> {
    const response = await _axios.post(
      '/v1/account/user/public/reset-password',
      input,
      {
        headers: {
          authorization: token,
        },
      }
    )
    return response.data
  }

  async removeUser(id: string): Promise<IAPISuccessMessage> {
    const response = await _axios.post(`/v1/account/user/delete-member/${id}`)
    return response.data
  }

  async updateSignature(
    input: Pick<IUser, 'signature'>
  ): Promise<IAPISuccessMessage> {
    const response = await _axios.put('/v1/account/user/signature', input)
    return response.data
  }

  async updateNotificationsSettings(
    input: Pick<IUser, 'settings'>
  ): Promise<IAPISuccessMessage> {
    const response = await _axios.put('/v1/account/user/settings', input)
    return response.data
  }
}

import { Link } from '@chakra-ui/react'
import AutoinspectorIcon from 'components/AutoinspectorIcon'
import { LANDING_PATH } from 'config/config.exporter'

interface IProps {
  url?: string
  iconSize?: string
}

function AutoinspectorLink(props: IProps) {
  return (
    <Link
      _hover={{ opacity: '0.7' }}
      href={props.url || LANDING_PATH}
      display='flex'
      alignItems='center'
    >
      <AutoinspectorIcon variant='primary' size={props.iconSize || '8rem'} />
    </Link>
  )
}

export default AutoinspectorLink

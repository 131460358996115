export enum ErrorsCodes {
  UNAUTHORIZED_USER = 'Unauthorized',
  NOT_FOUND_USER = 'not_found_user',
  ACCOUNT_NOT_VERIFIED = 'account_not_verified',
  BAD_CREDENTIALS = 'bad_credentials',
  USERNAME_ALREADY_EXIST = 'username_already_exist',
  EMAIL_ALREADY_EXIST = 'email_already_exist',
  EMAIL_NOT_EXIST = 'email_not_exist',
  ACCOUNT_ALREADY_VERIFIED = 'account_already_verified',
  COMPANY_NOT_BELONG_TO_THIS_USER = 'company_not_belong_to_this_user',
  INVALID_OLD_PASSWORD = 'invalid_old_password',
  ANY_TOKEN_PROVIDED = 'ANY_TOKEN_PROVIDED',
  ACCOUNT_ALREADY_CREATED = 'ACCOUNT_ALREADY_CREATED',
  USER_REACHED_LIMIT_COMPANIES_CREATED = 'user_reached_limit_companies_created',
  NOT_FOUND_COMPANY_ID = 'not_found_company_id',
  INVALID_ROLE = 'INVALID_ROLE',
  USER_ALREADY_EXIST_IN_COMPANY = 'user_already_exist_in_company',
  COMPANY_NOT_FOUND = 'company_not_found',
  COMPANY_NOT_VERIFIED_YET = 'company_not_verified',
  INSUFICCIENT_COMPANY_INFORMATION = 'insuficcient_company_information',
  INVALID_COMPANY_ID = 'invalid_company_id',
  TRANSACTION_FAILED = 'transaction_failed',
  ANY_COMPANY_ID_PROVIDED = 'any_company_id_provided',
  INVALID_BODY = 'invalid_body',
  CANNOT_ACCEPT_INVITATION = 'cannot_accept_invitation',
  COMPANY_ALREADY_REQUESTED_A_VERIFICATION = 'company_already_requested_a_verification',
  DB_INTERNAL_SERVER_ERROR = 'db_internal_server_error',
  WRONG_HIERARCHY = 'wrong_hierarchy',
  ANY_IMAGE_PROVIDED = 'any_image_provided',
  INVALID_IMAGE_FORMAT = 'invalid_image_format',
  IMAGE_TOO_BIG = 'image_too_big',
  FORBIDDEN_IMAGE_PROVIDED = 'forbidden_image_provided',
  SERVICE_NOT_AVAILABLE = 'service_not_available',
  INVALID_WEBHOOK_ENDPOINT = 'invalid_webhook_endpoint',
  WEBHOOK_ALREADY_CREATED = 'webhook_already_created',
  ANY_WEBHOOK_CREATED = 'any_webhook_created',
  UNHEALTHY_WEBHOOK_ENDPOINT = 'unhealthy_webhook_endpoint',
  EMAIL_ALREADY_EXISTS_IN_COMPANY = 'email_already_exists_in_company',
  INTERNAL_ID_ALREADY_EXISTS_IN_COMPANY = 'internalID_already_exists_in_company',
}

import { forwardRef } from 'react'
import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react'

export const ButtonChakra = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <ChakraButton ref={ref} {...props}>
        <span>{children}</span>
      </ChakraButton>
    )
  },
)
